.HeroBanner {
    background: #391037;
    height: 600px;
    width: 100%;
    left: 0px;
    padding-top: 40px;

}

.title {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 65px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 0px;

}
.copylink {
    background-color: #00A26D;
    font-size: .875rem;
    border-radius: 0.5rem;
    cursor: pointer;
    color: #fff;
    box-shadow: unset;
    font-weight: 500;
    line-height: 1.5;
    border: 0px;
    height: 23px;
    margin-top: 50px;
}

.subtitle {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 33px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 5px;
    margin-bottom: 5px;
}

.subtitleContainer {
    display: flex;
    justify-content: center;
}

.tinytext{
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 33px;
    text-align: center;
    color: #FFFFFF;
    margin-top: -15px;
    margin-bottom: 5px;
}

.chrome {
    margin-left: 5px;
    margin-top: 7px;
    height: 30px;
}


  .spinner{
    height: 25px;
    margin-bottom: 20px; 
    margin-top: 20px;
  }

.AvatarContainer {
    display: flex;
    justify-content: center;
}

.Avatar {
    margin-left: 25px ;
    margin-right: 25px ;
}

.dotContainer {
    position: relative;
    align-items: center;
    display: flex;
    justify-content: center;
    transform: translate(126px, -34px);
   
}

.greenDot {
    position: absolute;
    z-index: 2;
}
.purpleDot {
    position: absolute;
}

.slack {
    height: 16px;
}

.tipContainer{
    display: flex;
    justify-content: center;
    align-items: center;
}

.tip {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 33px;
    text-align: center;
    color: #FFFFFF;
    margin-left: 5px;
    z-index: 1;
    /* cursor: pointer; */
}