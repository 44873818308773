.mobileMiddleContainer{
    height: auto;
}


.titleMiddleMobile {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    color: rgba(27, 26, 27, 0.85);
    margin-bottom: 0px;
    margin-top: 0px;

}

.subtitleMiddleMobile {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: rgba(27, 26, 27, 0.85);
    margin-top: 5px;
    margin-bottom: 24px;
    
}

.stopSign {
    
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 87px;
    text-align: center;
    margin-top:45px;
    margin-bottom: 10px;

}

.joinClubButton {
    box-sizing: border-box;
    background: #FFFFFF;
    border: 3px solid #BDBDBD;
    border-radius: 10px;
    width: 160.01px;
    height: 48.25px;
    margin-right: 10px;
    margin-left: 10px;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: rgba(27, 26, 27, .85);
    margin-bottom: 50px;
    margin-top: 10px;
}

.joinClubButton:focus {
    background: #D5D4D4;
    border: 3px solid #7B7B7B;
}