.countDownTimer1 {
    display: flex;
    justify-content: center;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 54px;
    text-align: center;
    color: rgba(27, 26, 27, 0.85);
}

.countDownTimer2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 33px;
    text-align: center;
    color: rgba(27, 26, 27, 0.85);
}

.countDownTimerSubtext {
    margin-top: 5px;
    margin-bottom: 20px; 
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 33px;
    text-align: center;
    color: rgba(27, 26, 27, 0.85);
}

.zeros {
    margin-top: 20px;
    margin-bottom: 0px;
}