.buttonContainer {
    display: flex;
    justify-content: center;
}

.timerButton {
    box-sizing: border-box;
    background: #FFFFFF;
    border: 3px solid #BDBDBD;
    border-radius: 10px;
    width: 160.01px;
    height: 48.25px;
    margin-right: 10px;
    margin-left: 10px;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    color: rgba(27, 26, 27, .85);
}

.timerButton:hover {
    background: #D5D4D4;
    border: 3px solid #7B7B7B;
    cursor: pointer;
}

.stayAwakeTitle {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 54px;
    text-align: center;
    color: rgba(27, 26, 27, 0.85);
    margin-top: 30px;
    margin-bottom: 30px;

}

#active {
    background: #5A1D5C;
    color: #FFFFFF;
    border: 3px solid #5A1D5C;
    
}

#active:hover {
    opacity: 80%;

}

.stayAwakeContainer {
    min-height: 200px;
}

@keyframes jello-horizontal {
    0% { transform: scale3d(1, 1, 1); }
    30% { transform: scale3d(1.25, 0.75, 1); }
    40% { transform: scale3d(0.75, 1.25, 1); }
    50% { transform: scale3d(1.15, 0.85, 1); }
    65% { transform: scale3d(0.95, 1.05, 1); }
    75% { transform: scale3d(1.05, 0.95, 1); }
    100% { transform: scale3d(1, 1, 1); }
    }

    .timerButton[animation="1"] {
        animation: jello-horizontal 0.5s both;
        }