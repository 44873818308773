
.KeepGreenTitle1 {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 54px;
    text-align: center;
    color: rgba(27, 26, 27, 0.85);
    margin-top: 50px;
    margin-bottom: 30px;
}

.KeepGreenTitle2 {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 54px;
    text-align: center;
    color: rgba(27, 26, 27, 0.85);
    margin-top: 30px;
    margin-bottom: 30px;
}

.tabReloaderImage {
    border: 1px solid lightgray;
    margin-top: 10px;
    margin-bottom: 10px;
}

.howtoSAC {
width: 80%;
border: 1px solid lightgray;
margin-top: 30px;
margin-bottom: 20px;
}

.list {
    margin: 5px;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 33px;
    text-align: center;
    color: #000000;
    
}

.twitter {
    font-size: 40px;
    margin: 5px;
}

a, a:hover, a:visited, a:active {
    color: inherit;
    text-decoration: none;
   }

.listContainer{
    border: 1px solid rgba(0,0,0,.085);
    border-radius: 10px;
    width: 50%;
    text-align: center;
    margin: auto;
    margin-top: 60px;
    margin-bottom: 30px;
}