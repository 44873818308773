.HeroBannerMobile {
    background: #391037;
    height: 350px;
    width: 100%;
    left: 0px;
    padding-top: 40px;

}

.titleMobile {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 49px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: -5px;
    

}

.subtitleMobile {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 33px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 0px;
    margin-bottom: 45px;
}

.AvatarContainerMobile {
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.AvatarMobile {
    margin-left: -10px ;
    margin-right: -10px ;
}

.dotContainerMobile {
    position: relative;
    align-items: center;
    display: flex;
    justify-content: center;
    transform: translate(50px, -10px);
   
}

.greenDotMobile {
    position: absolute;
    z-index: 2;
}
.purpleDotMobile {
    position: absolute;
}

.susan {
    transform: translateY(35px);
}

.chris {
    transform: translateY(0px);
}

.evan {
    transform: translateY(-35px);
}