.footer {
    background: #F6F6F6;
    width: 100%;
    height: 120px;  
    padding-top: 20px;
    padding-bottom: 20px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footerTitle {
    margin: 5px;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 33px;
    text-align: center;
    color: #000000;
    
}

.footerTitle  a {
    color: #766df4;
    
}

.twitter {
    font-size: 40px;
    margin: 5px;
}

/* a, a:hover, a:visited, a:active {
    color: inherit;
    text-decoration: none;
   } */